
import {REMOVE_VEHICLES, SET_VEHICLES_TYPE, UPDATE_VEHICLES} from "../actions/VehicleActions";

/**
 * initial state
 */
const initialState = {
    vehiclesType: null,
    vehicles: []
};

const vehicleReducer = (state = initialState, action) => {

    switch(action.type) {
        case SET_VEHICLES_TYPE:
            return { ...state, vehiclesType: action.vehiclesType }

        case UPDATE_VEHICLES:
            return { ...state, vehicles: action.vehicles }

        case REMOVE_VEHICLES:
            return { ...state, vehicles: [] }

        default:
            return state;
    }
}

export default vehicleReducer
