import {useDispatch, useSelector} from "react-redux";
import React from 'react';
import {CircleSpinnerOverlay} from "react-spinner-overlay";
import {toggleLoader} from "../../actions/LoaderActions";

/**
 * loader
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Loader = () => {

    const loader = useSelector(state => state.loader)
    const dispatch = useDispatch();

    const close = () => {
        loader.onClose();
        dispatch(toggleLoader(false));
    }

    return (
        <>
            <CircleSpinnerOverlay
                 loading={loader.isLoading}
                 color={"#ffffff"}
                 message={
                     <>
                         <div color={"#ffffff"} className={"text-white text-center my-3 px-3"}>{loader.message ?? 'Yükleniyor, lütfen bekleyiniz...'}</div>
                         <button className={"btn btn-sm btn-danger"} onClick={() => close()}>Kapat</button>
                     </>
                 }
                 overlayColor="rgba(0,0,0,0.7)"
            />
        </>
    )
}

export default Loader;
