/**
 * log
 *
 * @param log
 */
export const consoleLog = (log) => {
    if(process.env.NODE_ENV !== 'production'){
        console.log(log);
    }
}
