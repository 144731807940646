
import bidbLogo from "../../assets/images/bidb-logo.svg"

const Footer = () => {
    return (
        <>
            <footer>
                <img src={bidbLogo} height="40" alt="" />
            </footer>
        </>
    )
}

export default Footer
