/**
 * imports
 */
import {LOADER_MESSAGE, LOADER_ON_CLOSE, TOGGLE_LOADER} from "../actions/LoaderActions";


/**
 * initial state
 *
 * @type {{isLoading: boolean, onClose: null, message: string}}
 */
const initialState = {
    isLoading: false,
    message: 'Yükleniyor, lütfen bekleyiniz...',
    onClose: () => null
};

const loaderReducer = (state = initialState, action) => {

    switch(action.type) {

        case TOGGLE_LOADER:
            return { ...state, isLoading: action.isLoading }

        case LOADER_ON_CLOSE:
            return { ...state, onClose: action.onClose }

        case LOADER_MESSAGE:
            return { ...state, message: action.message }

        default:
            return state;
    }
}

export default loaderReducer
