import React from 'react';
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch, useSelector} from "react-redux";
import {closePlayer} from "../../actions/StreamActions";
import ReactPlayer from "react-player";
import HumanTimer from "react-human-timer";

const Player = () => {

    const stream = useSelector(state => state.stream)
    const dispatch = useDispatch();

    /**
     * close player
     */
    const close = () => {
        dispatch(closePlayer());
    }

    return(
        <>
            <Modal
                contentClassName={"player"}
                centered
                size={"lg"}
                show={stream.show}
                onHide={() => close()}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <div className={"w-100 d-flex justify-content-between align-items-center"}>
                        <Modal.Title>{stream.name}</Modal.Title>
                        <div className={"text-danger me-3"}>
                            <HumanTimer
                                onEnd={(timer) => close()}
                                active={true}
                                zeroes={false}
                                seconds={45}>
                                {
                                    (timer) => (
                                        <>
                                            {timer.seconds} sn
                                        </>
                                    )
                                }
                            </HumanTimer>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ReactPlayer width={"100%"} height={"100%"} pip={false} url={stream.url} playing={true} controls={true}/>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Player
