import {consoleLog} from "../helpers/ConsoleLog";
import axios from "axios";
import {toggleLoader} from "./LoaderActions";
import {immediateToast} from "izitoast-react";

export const UPDATE_VEHICLES = 'UPDATE_VEHICLES';
export const REMOVE_VEHICLES = 'REMOVE_VEHICLES';
export const SEARCH_VEHICLES = 'SEARCH_VEHICLES';
export const SET_VEHICLES_TYPE = 'SET_VEHICLES_TYPE';

export const VEHICLES_ALL = 'VEHICLES_ALL';
export const VEHICLES_ONLY_ACTIVE = 'VEHICLES_ONLY_ACTIVE';

/**
 * set vehicles type
 *
 * @param vehiclesType
 * @returns {{type: string, vehiclesType}}
 */
export const setVehiclesType = (vehiclesType) => ({
    type: SET_VEHICLES_TYPE,
    vehiclesType
})

/**
 * search vehicles
 *
 * @param searchTerm
 * @returns {{searchTerm, type: string}}
 */
export const searchVehicles = (searchTerm) => ({
    type: SEARCH_VEHICLES,
    searchTerm
})

/**
 * update vehicles
 *
 * @param vehicles
 * @returns {{vehicles, type: string}}
 */
export const updateVehicles = (vehicles) => ({
    type: UPDATE_VEHICLES,
    vehicles
})

/**
 * remove vehicles from state
 *
 * @returns {{type: string}}
 */
export const removeVehicles = () => ({
    type: REMOVE_VEHICLES,
})

/**
 * get vehicles from api
 *
 * @param type
 * @returns {function(*): unknown}
 */
export const getVehicles = (type) => {

    if(!type) type = VEHICLES_ONLY_ACTIVE;
    setVehiclesType(type);

    return async (dispatch) =>  {

        dispatch(toggleLoader(true));
        const params = {}
        if (type === VEHICLES_ONLY_ACTIVE){
            params.filter = {
                state: 1
            }
        }

        await axios.get('vehicles/' + process.env.REACT_APP_VEHICLE_GROUP, {params})
            .then(response => {
                dispatch(toggleLoader(false));

                const vehicles = response.data.data;
                dispatch(updateVehicles(vehicles ?? []));

                if (vehicles == null){
                    immediateToast('warning', {
                        message: "Araç bulunamadı",
                    });
                }
            })
            .catch(error => {
                dispatch(toggleLoader(false));
                consoleLog(error);
                dispatch(updateVehicles([]));
                immediateToast('error', {
                    title: "Hata oluştu",
                    message: 'Lütfen tekrar deneyiniz',
                });
                //dispatch(hideLoading());
            }).finally(() => {
                //dispatch(hideLoading());
            });
    }
}

/**
 * get vehicles by plate
 *
 * @param plate
 * @returns {function(*): unknown}
 */
export const getVehiclesByPlate = (plate) => {
    return async (dispatch) => {
        dispatch(toggleLoader(true));
        const params = {
            filter: {
                plate: plate
            }
        }

        await axios.get('vehicles/' + process.env.REACT_APP_VEHICLE_GROUP, {params})
            .then(response => {
                dispatch(toggleLoader(false));
                const vehicles = response.data.data;
                dispatch(updateVehicles(vehicles ?? []));
                if (vehicles == null){
                    immediateToast('warning', {
                        message: "Araç bulunamadı",
                    });
                }
            })
            .catch(error => {
                dispatch(toggleLoader(false));
                consoleLog(error);
                dispatch(updateVehicles([]));
                immediateToast('error', {
                    title: "Hata oluştu",
                    message: 'Lütfen tekrar deneyiniz',
                });
                //dispatch(hideLoading());
            }).finally(() => {
                //dispatch(hideLoading());
            })
    }
}

