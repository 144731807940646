import React, {useEffect} from 'react';
import WebFont from 'webfontloader';

import Home from './pages/Home';
import Layout from "./components/layout/Layout";

function App() {

  useEffect(() => {
        WebFont.load({
          google: {
            families: ['Urbanist:300,400,500,600,700']
          }
        });
      },

      []);

  return (
      <>
        <Layout>
          <Home />
        </Layout>
      </>
  )
}

export default App;
