import {useDispatch} from "react-redux";
import {
    getVehicles,
    getVehiclesByPlate, VEHICLES_ALL,
    VEHICLES_ONLY_ACTIVE,
} from "../../actions/VehicleActions";
import {useState} from "react";
import {consoleLog} from "../../helpers/ConsoleLog";
import abbLogo from "../../assets/images/abb-logo.svg"
import React from 'react';

const Header = (props) => {

    const dispatch = useDispatch();
    const [searchPlate, setSearchPlate] = useState('');

    /**
     * search plate
     *
     * @param event
     */
    const search = (event) => {
        consoleLog(searchPlate);
        dispatch(getVehiclesByPlate(searchPlate));
        event.preventDefault();
    }

    return(
        <>
            <header className="navbar navbar-expand-md justify-content-between">
                <a href="/" className="navbar-brand">
                    <img src={abbLogo} alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-display="collapse" data-bs-toggle="collapse"
                        data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <i className="fal fa-bars fs-1"/>
                </button>
                <div className="collapse navbar-collapse flex-grow-0" id="navbar-menu">
                    <ul className="navbar-nav">
                        {/*<li className="nav-item dropdown">*/}
                        {/*    <button className={"nav-link dropdown-toggle"} data-bs-toggle="dropdown"><i className="fal fa-calendar-clock"/> Tarih Bazlı Çalışmalar</button>*/}
                        {/*    <div className="dropdown-menu">*/}
                        {/*        asd*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        <li className="nav-item"><button className={"nav-link"} onClick={() => dispatch(getVehicles(VEHICLES_ONLY_ACTIVE))}><i className="fal fa-snowplow live"/> Görevdeki Araçlar</button></li>
                        <li className="nav-item"><button className={"nav-link"} onClick={() => dispatch(getVehicles(VEHICLES_ALL))}><i className="fal fa-snowplow"/> Tüm Araçlar</button></li>
                        <li className="nav-item nav-search">
                            <form className="input-group input-group-sm" onSubmit={search}>
                                <input type="text" className="form-control" id="plate" value={searchPlate} onChange={(e) => setSearchPlate(e.target.value)} placeholder="Plaka" aria-label="Plaka" aria-describedby="basic-addon2" autoComplete={"off"} />
                                <button className="btn btn-secondary btn-search" type="submit"><i className="fal fa-search"/></button>
                            </form>
                        </li>
                    </ul>
                </div>
            </header>
        </>
    )
}

export default Header
