/**
 * imports
 */
import {CLOSE_PLAYER, SHOW_PLAYER} from "../actions/StreamActions";
import {consoleLog} from "../helpers/ConsoleLog";
import {useSelector} from "react-redux";

/**
 * initial state
 *
 * @type {{show: boolean, name: string, url: string}}
 */
const initialState = {
    show: false,
    nodeId: '',
    name: '',
    url: ''
};

const streamReducer = (state = initialState, action) => {

    consoleLog(action);
    switch(action.type) {

        case SHOW_PLAYER:
            return { ...state, show: action.show, nodeId: action.nodeId, name: action.name, url: action.url }

        case CLOSE_PLAYER:
            return { ...state, show: action.show, nodeId: '', name: '', url: '' }

        default:
            return state;
    }
}

export default streamReducer
