
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const LOADER_MESSAGE = 'LOADER_MESSAGE';
export const LOADER_ON_CLOSE = 'LOADER_ON_CLOSE';

export const toggleLoader = (isLoading) => {
    return {
        type: TOGGLE_LOADER,
        isLoading: isLoading
    }
}

export const setLoaderOnClose = (onClose) => {
    return {
        type: LOADER_ON_CLOSE,
        onClose: onClose
    }
}

export const setLoaderMessage = (message) => {
    return {
        type: LOADER_MESSAGE,
        message: message
    }
}
