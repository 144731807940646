import {useDispatch} from "react-redux";
import {consoleLog} from "../helpers/ConsoleLog";
import {Marker, Popup, Tooltip} from "react-leaflet";
import L from "leaflet";
import {forwardRef} from "react";
import {startStream} from "../actions/StreamActions";
import React from 'react';

export const CustomMarker = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const vehicle = props.vehicle;

    const icon = () => {

        const content = document.createElement('div');
        content.innerHTML =
            '<div class="custom-marker'+ (vehicle.state === 1 ? ' active' : '')+'">' +
            '   <span class="marker-camera"><i class="fal fa-video-camera"></i></span>' +
            '   <span class="marker-plate">'+vehicle.plate+'</span>' +
            '   <span class="marker-icon"><i class="fa fa-snowplow"></i></span>' +
            '</div>';

        return new L.divIcon({
            html: content,
            iconSize: [30, 30],
            iconAnchor: [25, 15]
        });
    }

    /**
     * show stream
     */
    const stream = () => {
        dispatch(startStream(vehicle));
        consoleLog('Start Stream: '+vehicle.deviceNo);
    }

    return (
        <>
            <Marker
                ref={ref}
                id={vehicle.nodeId}
                position={[vehicle.lat, vehicle.lon]}
                icon={icon()}
            >
                <Tooltip direction="top" offset={[0, -20]} opacity={1}>
                    {vehicle.plate}
                </Tooltip>
                {vehicle.state === 1 && <Popup>
                    <strong className={"popup-plate"}>{vehicle.plate}</strong>
                    <button onClick={event => stream()} className={"btn btn-danger btn-camera"}><i className={"fal fa-video-camera"}></i> Başlat</button>
                </Popup>}
            </Marker>
        </>
    )
});

export default CustomMarker
