import {combineReducers} from "redux";
import vehicleReducer from "./VehicleReducer";
import streamReducer from "./StreamReducer";
import loaderReducer from "./LoaderReducer";

const appReducer = combineReducers({
    vehicle: vehicleReducer,
    loader: loaderReducer,
    stream: streamReducer,
});

const rootReducer = (state, action) => {
    return appReducer(state, action)
};

export default rootReducer;
