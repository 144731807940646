import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from "react-redux";
import {getVehicles} from "../actions/VehicleActions";
import Player from "../components/layout/Player";
import {immediateToast} from "izitoast-react";
import {consoleLog} from "../helpers/ConsoleLog";
import {MapContainer, TileLayer,} from "react-leaflet";
import mqtt from "mqtt"; // import namespace "mqtt"

import "leaflet/dist/leaflet.css";
import CustomMarker from "../components/CustomMarker";
import {LatLng} from "leaflet/src/geo";
import MarkerClusterGroup from "react-leaflet-cluster";
import Loader from "../components/layout/Loader";

const Home = (props) => {

    const dispatch = useDispatch();
    const vehiclesType = props.vehiclesType;
    const vehicles = props.vehicles;

    const [client, setClient] = useState(null);
    const [map, setMap] = useState(null);


    /**
     * marker refs
     * @type {React.MutableRefObject<*[]>}
     */
    const markerRefs = useRef([]);

    /**
     * use effect
     */
    useEffect(() => {

        if (client == null){
            const mqttOptions = {
                auth: process.env.REACT_APP_MQTT_USER+':'+process.env.REACT_APP_MQTT_PASS,
                username: process.env.REACT_APP_MQTT_USER,
                password: process.env.REACT_APP_MQTT_PASS,
                port: process.env.REACT_APP_MQTT_PORT,
                path: process.env.REACT_APP_MQTT_PATH,
                protocol: process.env.REACT_APP_MQTT_PROTOCOL
            }
            const client = mqtt.connect(process.env.REACT_APP_MQTT_BROKER_URL, mqttOptions)
            setClient(client);
        }

        if (client) {
            consoleLog(client)
            client.on('connect', () => {
                consoleLog("connected");

                const topic = 'ats/karkureme';
                const qos = 0;
                client.subscribe(topic, { qos }, (error) => {
                    if (error) {
                        console.error('mqtt subscription error', error)
                    }
                    //setIsSub(true)
                });
            });
            client.on('error', (err) => {
                console.error('mqtt connect error: ', err);
                client.end();
            });
            client.on('reconnect', () => {
                //setConnectStatus('Reconnecting');
            });
            client.on('message', (topic, message) => {
                const response = JSON.parse(message.toString());
                if(response.p === 'mark'){
                    if(markerRefs.current[response.n] != null){
                        markerRefs.current[response.n].setLatLng(new LatLng(response.y, response.x));
                        markerRefs.current[response.n].setTooltipContent(response.ad);
                    }
                } else if(response.p === 'reload' && response.data.reload){
                    map.closePopup();
                    dispatch(getVehicles(vehiclesType));
                    immediateToast("info", {
                        message: "Araçlar yeniden yüklendi",
                        timeout: 2000
                    });
                } else if(response.p === 'started'){
                    map.closePopup();
                    dispatch(getVehicles(vehiclesType));
                    immediateToast("success", {
                        message: response.n+' - Araç çalışmaya başladı',
                        timeout: 2000
                    });
                } else if(response.p === 'stopped'){
                    map.closePopup();
                    dispatch(getVehicles(vehiclesType));
                    immediateToast("error", {
                        message: response.n+' - Araç çalışmayı durdurdu',
                        timeout: 2000
                    });
                }
            });
        }

        // dispatch vehicles
        dispatch(getVehicles(vehiclesType));

    }, [map, client, dispatch, vehiclesType]);

    /**
     * render marker
     * @param vehicles
     * @returns {*}
     */
    const addMarkers = (vehicles) => {

        markerRefs.current = [];
        return vehicles.filter(vehicle => {
            return !(!vehicle.lat || !vehicle.lon);
        }).map((vehicle, index) => {
            return (
                <>
                    <CustomMarker index={index} key={vehicle.nodeId} vehicle={vehicle} ref={ref => markerRefs.current[vehicle.nodeId] = ref} />
                </>
            );
        });
    };

    const position = [39.9296204,32.8328221]
    return (
        <>
            <Loader />
            <Player />
            <MapContainer ref={setMap} center={position} attributionControl={false} zoom={12} maxZoom={19} scrollWheelZoom={true} id={"map"} >
                <TileLayer
                    attribution='&copy; <a href="https://www.ankara.bel.tr">Ankara Büyükşehir Belediyesi</a>'
                    url="https://www.google.com/maps/vt?lyrs=m&gl=tr&x={x}&y={y}&z={z}"
                    key="{x}{y}{z}"
                />
                <MarkerClusterGroup
                    chunkedLoading
                    preferCanvas={false}
                >
                {vehicles && vehicles.length > 0 && addMarkers(vehicles) }
                </MarkerClusterGroup>
            </MapContainer>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        vehiclesType: state.vehicle.vehiclesType,
        vehicles: state.vehicle.vehicles,
    }
};

export default connect(mapStateToProps)(Home);
