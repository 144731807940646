import SetInterval from 'set-interval';
import axios from "axios";
import {immediateToast} from "izitoast-react";
import {setLoaderMessage, setLoaderOnClose, toggleLoader} from "./LoaderActions";
import {consoleLog} from "../helpers/ConsoleLog";

export const SHOW_PLAYER = 'SHOW_PLAYER';
export const CLOSE_PLAYER = 'CLOSE_PLAYER';

/**
 * show player
 *
 * @param nodeId
 * @param name
 * @param url
 * @returns {{show: boolean, nodeId, name, type: string, url}}
 */
export const showPlayer = (nodeId, name, url) => {
    return {
        type: SHOW_PLAYER,
        show: true,
        nodeId: nodeId,
        name: name,
        url: url
    }
}

/**
 * close player
 *
 * @returns {{show: boolean, type: string}}
 */
export const closePlayer = () => {
    SetInterval.clear('streamTimer');
    return {
        type: CLOSE_PLAYER,
        show: false
    }
}

/**
 * check stream
 *
 * @param vehicle
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkStream = (vehicle) => {
    return axios.get('streams/'+vehicle.deviceNo+'/check').then(response => {
        return response.data.data;
    });
}

/**
 * start stream
 *
 * @param vehicle
 * @returns {function(*): unknown}
 */
export const startStream = (vehicle) => {

    return async (dispatch) =>  {

        dispatch(setLoaderMessage("Bağlanılıyor... Araç konumuna ve internet hızına göre bağlantı süresi değişkenlik gösterebilir"));
        dispatch(setLoaderOnClose(function() {
            SetInterval.clear('streamTimer');
            consoleLog('click close');
        }));

        dispatch(toggleLoader(true));
        await axios.get('streams/' + vehicle.deviceNo+'/start')
            .then(response => {
                const data = response.data.data;
                consoleLog(data);
                if(data.status){
                    if(data.started){
                        dispatch(toggleLoader(false));
                        dispatch(showPlayer(vehicle.nodeId, vehicle.plate, data.stream));
                        SetInterval.clear('streamTimer');
                    } else {
                        SetInterval.start(() => {
                            checkStream(vehicle).then(result => {
                                consoleLog(result);
                                if(result.status){
                                    dispatch(toggleLoader(false));
                                    dispatch(showPlayer(vehicle.nodeId, vehicle.plate, result.stream));

                                    SetInterval.clear('streamTimer');
                                }
                            });
                        }, 5000, 'streamTimer');
                    }
                } else {
                    immediateToast('error', {
                        title: "Hata oluştu",
                        message: data.message ?? 'Lütfen tekrar deneyiniz',
                    });
                    dispatch(toggleLoader(false));
                }
            })
            .catch(error => {
                dispatch(toggleLoader(false));
                immediateToast('error', {
                    title: "Hata oluştu",
                    message: error.toString(),
                });
            });/*.finally(() => {
                dispatch(toggleLoader(false));
                //dispatch(hideLoading());
            });*/
    }
}
